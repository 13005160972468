import React, {useRef, useState} from "react";
import {NavLink} from "../components/header/MainHeader";
import {
    convertToFloorplanCardData,
    DriveGallery,
    FeatureSection,
    FloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection,
    PropertyLocation,
    sortFloorplans,
    TeamSection,
    trackContactClicked,
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {Helmet} from "react-helmet-async";
import {communityAmenities, longTerm, propertyId} from "../services/DataService";
import {NavItem} from "../components/nav/NavProps";
import {PageProps} from "../data/PageProps";
import {MainLayout} from "../components/layout/MainLayout";
import {ActionSection} from "../components/action/ActionSection";

export const LongTerm: React.FC<PageProps> = ({property, contact, isLoading}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const furnishedFloorplanRef = useRef<HTMLDivElement>(null);
    const unFurnishedFloorplanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const furnishedSectionRef = useRef<HTMLDivElement>(null);
    const unfurnishedSectionRef = useRef<HTMLDivElement>(null);

    const primaryNavs: NavItem[] = [
        {
            anchor: "#furnished",
            text: "furnished apts",
            handleRef: () => {
                handleRefToFurnished();
            }
        },
        {
            anchor: "#unfurnished",
            text: "unfurnished apts",
            handleRef: () => {
                handleRefToUnfurnished();
            }
        }
    ];
    const secondaryNavs: NavItem[] = [
        {
            anchor: "#apply",
            text: "apply",
            handleRef: () => {
                setShowApplicationModal(true);
            }
        },
        {
            anchor: "#location",
            text: "location",
            handleRef: () => {
                handleRefToMap()
            }
        },
        {
            anchor: "#contact",
            text: "contact",
            handleRef: () => {
                setShowContactModal(true);
                trackContactClicked("summer-house");
            }
        }
    ];
    const leftLink: NavLink = {
        text: "Looking for Short Term?",
        to: "/short-term"
    };
    const handleRefToFurnishedFloorPlan = () => {
        furnishedFloorplanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToUnFurnishedFloorPlan = () => {
        furnishedFloorplanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToFurnished = () => {
        furnishedSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToUnfurnished = () => {
        unfurnishedSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
    };
    return (
        <MainLayout contact={contact} property={property} handleRefToMap={handleRefToMap}
                    showContactModal={showContactModal} showApplicationModal={showApplicationModal}
                    leftLink={leftLink} primaryNavs={primaryNavs} secondaryNavs={secondaryNavs}
                    setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}
                    contactClickHandler={() => {
                        setShowContactModal(true);
                        trackContactClicked("summer-house");
                    }}>
            <Helmet>
                <title>SummerHouse Cottage-inspired 1, 2 & 3 bedroom apartments Bloomington IN</title>
                <meta name="description"
                      content="SummerHouse at Indiana - Cottage-Inspired, 1, 2 & 3 bedroom apartments, Lofts & townhomes, with all utilities included, 1.5 miles east of IU campus in Bloomington."/>
            </Helmet>
            {isLoading ? "" : <>

                <HeroSection
                    videoUrl="https://www.googleapis.com/drive/v3/files/10anjEDcWKan_Wd-kGNDvPgruKp9r_8Cc?alt=media&key=AIzaSyAdG4u5YD2CZvQTv_hRtaKrmSNWZkY30oU"/>
                <FeatureSection>
                    <div className="container">
                        <h2>Stay a while...</h2>
                        <p>SummerHouse features thoughtfully designed cottage-inspired apartments with you and your life
                            in mind, just 1.5 miles east of IU. Choose a 1 & 2-bedroom flat or loft or a 2 & 3-bedroom
                            townhome. Furnished and unfurnished options are available.</p>
                    </div>
                </FeatureSection>
                <ActionSection handleRefToApply={() => setShowApplicationModal(true)}
                               handleRefToFurnishedFloorPlan={handleRefToFurnishedFloorPlan}
                               handleRefToUnfurnishedFloorPlan={handleRefToUnFurnishedFloorPlan}
                               handleRefToContact={() => {
                                   setShowContactModal(true);
                                   trackContactClicked("summer-house");
                               }} isLoading={isLoading}
                               floorplans={property.floorplans}/>
                <AmenitiesSection title="Community Amenities" amenities={communityAmenities}/>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Community Photos</span></h2>
                    <DriveGallery
                        driveId="1g7ljdrg1TcFKbEzJcEyKJaoxbABrMcbd"
                        initialSize={6}
                        type="simple"
                        showName={false}
                        propertyId={propertyId}
                    />
                </div>
                <div ref={furnishedSectionRef} id="furnished" className="reference">
                </div>
                <AmenitiesSection title="Furnished Apartment Amenities" amenities={longTerm.furnishedAmenities}/>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Furnished Apartment Photos</span></h2>
                    <DriveGallery
                        propertyId={propertyId}
                        driveId="1f-c3kSnGz2AOU_wGV7B7hNE_9ODefFhr"
                        initialSize={6}
                        type="simple"
                        showName={false}
                    />
                </div>
                <div ref={furnishedFloorplanRef} id="furnished-floorplan" className="reference">
                </div>
                {isLoading ?
                    <FloorplanSectionSkeleton/> :
                    <>{property?.floorplans ? <FloorplansSection
                        propertyId={propertyId}
                        title="Furnished Apartment FloorPlans"
                        floorplans={
                            sortFloorplans(
                                property.floorplans.filter(floorplan => floorplan.active)
                                    .filter(floorplan => floorplan.units.some(unit => unit.furnished))
                                    .map(floorplan => convertToFloorplanCardData(floorplan)
                                    ), "featured")
                        }/> : ""}

                    </>
                }
                <div ref={unfurnishedSectionRef} id="unfurnished" className="reference">
                </div>
                <AmenitiesSection title="Unfurnished Apartment Amenities" amenities={longTerm.unfurnishedAmenities}>
                    <h4><i>Select Units Include:</i></h4>
                    <ul>
                        <li>Private patio or deck</li>
                        <li>Icemaker & built-in microwave</li>
                        <li>Kitchen island</li>
                        <li>Window seat with hidden storage</li>
                        <li>USB Charging wall outlets</li>
                        <li>Dogs Welcome</li>
                    </ul>
                </AmenitiesSection>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Unfurnished Apartment Photos</span></h2>
                    <DriveGallery
                        driveId="1A0-p4jwO1unXRNLKwlLgy-4JxBW2mD3p"
                        initialSize={6}
                        type="simple"
                        showName={false}
                        propertyId={propertyId}
                    />
                </div>
                <div ref={unFurnishedFloorplanRef} id="unfurnished-floorplan" className="reference">
                </div>
                {isLoading ?
                    <FloorplanSectionSkeleton/> :
                    <>{property?.floorplans ? <FloorplansSection propertyId={propertyId}
                                                                 title="Unfurnished Apartment FloorPlans"
                                                                 floorplans={
                                                                     sortFloorplans(
                                                                         property.floorplans.filter(floorplan => floorplan.active)
                                                                             .filter(floorplan => floorplan.units.some(unit => !unit.furnished))
                                                                             .map(floorplan => convertToFloorplanCardData(floorplan)
                                                                             ), "featured")
                                                                 }/> : ""}

                    </>
                }

                <div ref={mapRef} id="location" className="reference">
                </div>
                <div className="container">
                    <h2 className="heading"><span className="emphasized">Location</span></h2>
                </div>
                <PropertyLocation property={property} isLoading={isLoading} handleRefToContact={() => {
                    setShowContactModal(true);
                    trackContactClicked("summer-house");
                }}/>


                <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>

            </>}


        </MainLayout>
    );
};
